<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--<div class="note">(*) Nội dung không được bỏ trống</div>-->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"
        />

      </div>
    </div>
  </div>
</template>

<script>
// import gql from "graphql-tag";
// import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
import moment from "moment";
// import {DATE} from "sequelize";
//import firebase from "../../../../firebase.json";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Thêm ngày nghỉ",
      mode: 'add',
      models: {},
      models_company: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction:null
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.params.id);
    this.mode = this.$route.name === 'editHoliday' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa Ngày Nghỉ' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_HOLIDAY);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-holiday' });
    },
    isDate1BeforeDate2(date1, date2) {
      return moment(date1).isBefore(date2);
    },
    async saveData(params) {
      if (params !== undefined && Object.entries(params).length > 0) {

        console.log("mcmcmx",params.date)
        //lấy danh sách ngày nghỉ đã chọn trước đó
        var gqLQueryList = DataServices.getList('holiday_item');
        console.log("gqL",gqLQueryList)
        var resData = await this.$apollo.mutate({
          mutation: gqLQueryList,
          variables: {
            where_key: {
              deleted: {'_eq': false},
              holiday: {
                company_id: {'_eq': params.company_id},
                active: {'_eq': true},
                deleted: {'_eq': false}
              }
            }
          }
        });
        var listData = resData.data['holiday_item'];
        console.log("uuwquwqwq",listData)


        var uid = this.$store.getters.user
        var updated_at = new Date();
        var year = ''
        year = params.year
        console.log("sangggggg",params)
        var objects = {}
        var text_date_all = ''
        var convert_date = []
        for (var i = 0 ; i < params.date.length ; i++)
        {
          for (var k = 0 ; k < listData.length ; k++){
            console.log("pargram.id",params.id,"listData",listData)
             convert_date = moment(listData[k].date).format("MM-DD-yyyy")
            var check_day = moment(params.date[i].date).format("MM-DD-yyyy")
            var current_day = new Date()
            current_day = moment(current_day).format("MM-DD-yyyy")
            console.log("convert_date",convert_date,"params.date[i].date",params.date[i].date)
            console.log("this.isDate1BeforeDate2",this.isDate1BeforeDate2(check_day,current_day))

            //kiểm tra ngày nghỉ trong quá khứ
            if (this.isDate1BeforeDate2(check_day,current_day) == true )
            {
              alert("Không được chọn ngày quá khứ !")
              return
            }
            //kiểm tra ngày nghỉ đã tồn tại
            if (convert_date == check_day && params.id != listData[k].holiday_id )
            {
              console.log("date1:",check_day,"date2:",convert_date)
              alert("Ngày đã tồn tại Vui lòng chọn ngày khác")
              return
            }


            console.log("convert:",convert_date)
          }


          text_date_all  += moment(params.date[i].date).format("MM/DD")+", "
        }

        console.log("text_date_all",text_date_all)
          objects = {
            'company_id': params.company_id,
            'year': year,
            'updated_by': uid,
            'updated_at': updated_at,
            'text_date_all': text_date_all,
          }

        console.log('objecttt',objects)
        var object_item = {
          'date':params.date
        }
        console.log("object_item",object_item)
        var that = this;
        if (this.mode === 'add') {
          var dataInsert = this.$commonFuction.convertJsonObject(objects);

          await that.$apollo
              .mutate({
                mutation: DataServices.insertData('holiday'),
                variables: {
                  objects: dataInsert
                }
              })
              .then( async (res)  => {
                if (res.data[Object.keys(res.data)].affected_rows)
                {
                   var returning = res.data[Object.keys(res.data)].returning[0];
                  var length = params.date ? params.date :[]
                  for (var i =0 ; i < length.length ; i++)
                  {
                    console.log("ngaythemvao:",params.date[i].date)
                    // params.date[i].date = moment(params.date[i].date).format("DD-MM-YYYY")
                    console.log("ngaythemvao:",params.date[i].date.length)
                    var holiday_item={
                      "date":object_item.date[i].date.trim(),
                      "holiday_id":returning.id,
                    }
                    console.log("holiday_item",holiday_item.date.length)
                    await that.$apollo
                        .mutate({
                          mutation: DataServices.insertData('holiday_item'),
                          variables: {
                            objects: holiday_item
                          }
                        })

                  }
                }
                this.$commonFuction.is_loading(false);
                that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
                this.$router.push({path: '/admin/list-holiday'});
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });

                this.$commonFuction.is_loading(false);
              });
        }
// edit
        if (this.mode === 'edit') {
          var id = this.models.id;
          var dataUpdate = this.$commonFuction.convertJsonObject(objects);

          var deleted ={
            "deleted":"true"
          }
          let variables = {
            'data_update': dataUpdate,
            'where_key': {
              'id': {'_eq': id}
            }
          }

          let update_holiday_items = {
            'data_update': deleted,
            'where_key': {
              'holiday_id': {'_eq': id}
            }
          }
          console.log("variables",variables)
          await this.$apollo.mutate({
            mutation: DataServices.updateData('holiday'),
            variables: variables
          })
          await this.$apollo.mutate({
            mutation: DataServices.updateData('holiday_item'),
            variables: update_holiday_items
          })
         .then( async (res)  => {
           var length = params.date ? params.date:[]
           console.log(res);//
           for (var i =0 ; i < length.length ; i++)
           {
             console.log("wqwqwqwqwq",params.date[i])
             console.log("ngaythemvao:",params.date[i].date)
             var holiday_items={
               "date":params.date[i].date.trim(),
               "holiday_id":id,
             }
             console.log("holiday_item_edit",holiday_items)
             await that.$apollo
                 .mutate({
                   mutation: DataServices.insertData('holiday_item'),
                   variables: {
                     objects: holiday_items
                   }
                 })

           }

                this.$commonFuction.is_loading(false);
                that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Cập nhật thành công', life: 3000});
                this.$router.push({path: '/admin/list-holiday'});
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });
                this.$commonFuction.is_loading(false);
                // this.summitEvent = false;
              });
        }
      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('holiday'),
        variables: {
          where_key: {
            id: {'_eq': id},
          }
        },
        fetchPolicy: "network-only"
      })
      console.log("datadata:",data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },
  },
  apollo: {
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>
<style>
.list-group-v {
  /* display: -webkit-box;
   display: -ms-flexbox;*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*-ms-flex-direction: column;*/
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-v:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.list-group-item-v {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125) ;
  height: 50px;
}
.box-check-box{
  position: relative;
}
.box-check-box label{
  position: absolute;
  left: 0px;
  top: 2px;
}
.box-check-box .check-box-day{
  position: absolute;
  right: 0px;
  top: 2px;
}
</style>