<template>
  <form id="frm_action">
    <div class="p-formgrid">
<!--      <div>-->
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Công ty<span style="color: red"> *</span></label>
          <div class="p-col">
            <v-select :clearable="false" title="Cong ty" @search="SearchCompany" class="input_width margin_input" id= "company" :filterable="false" placeholder="Chọn" v-model="selectedCompany" :options="company" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_company">
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right" >Năm <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false" v-on:input="change_year" title="Nam" class="input_width margin_input " id= "year" :filterable="false" placeholder="Chọn" v-model="selectedYear" :options="year" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_year">
          </div>
        </div>



        <label  class="p-col-fixed txt-right p-card-field" id="test">Danh sách ngày nghỉ</label><br>
        <label class="p-col-fixed txt-right p-card-field" id="tet"> Chọn ngày <span style="color: red">*</span></label>
        <div   class="field col-12 md:col-4 div-date">
          <Calendar :yearRange="rangeYear" :disabledDates="arr_only_date_disable" :minDate="minDate" :maxDate="maxDate" :yearNavigator="true" :monthNavigator="true" class=" margin input_width border_color" selectionMode="multiple"  dateFormat="dd-mm-yy" placeholder="DD/MM/YYYY"  id="date" v-model="date" :showIcon="true" />
          <button id="button_add_date" type="button" @click="add_input" class="p-button p-component p-button-icon-only p-button-add button_add div-date"><span class="pi pi-plus p-button-icon"></span><span class="p-button-label">&nbsp;</span><!----><span class="p-ink"></span></button>
        </div>
        <div  v-for="(item, index) in arr_date" :key="index"  class="item-day">
          {{item.date}}
              <Button class="button-delete" style="margin: 20px 0px 0px 12px;height: 33px!important ; width: 35px !important;"  icon="pi pi-trash" @click="delete_date(item.key)"  />
        </div>
<!--      </div>-->

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices'
// import { format } from 'date-fns';
import moment from 'moment';
import dataServices from "@/core/DataServices";
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      checked: true,
      holiday:{
        test:null
      },
      viewDate: new Date(2022, 5, 1), // Tháng và năm bạn muốn hiển thị (tháng 6, năm 2022)
      selectedYear:null,
      selectedCompany: null,
      year: [
        // {name: '2023', value: '2023'},
        // {name: '2024', value: '2024'},
        // {name: '2025', value: '2025'},
        // {name: '2026', value: '2026'},
        // {name: '2027', value: '2027'},
        // {name: '2028', value: '2028'},
        // {name: '2029', value: '2029'},
        // {name: '2030', value: '2030'},
        // {name: '2031', value: '2031'},
        // {name: '2032', value: '2032'},
        // {name: '2033', value: '2033'},

      ],
      company: null,
      data: '',
      date: null,
      arr_date: [],
      arr_only_date: [],
      arr_only_date_disable: [],
      item_date: [],
      list_company_all: [],
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      minDate: null,
      maxDate: null,
      rangeYear: new Date().getFullYear()+':'+new Date().getFullYear(),
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {


      const currentYear = new Date().getFullYear();
      console.log("currentYear:",currentYear)
      const startYear = 2014; // hoặc năm bắt đầu mong muốn
      var count_check =0;
      for (let i = startYear; i<currentYear+100; i++) {
        if (i>=currentYear && count_check<11){
          this.year.push(i);
          count_check++
        }
      }
    if (this.mode == 'add') {
      this.selectedYear = currentYear
      this.$set(this, 'date', [new Date(parseInt(this.selectedYear), new Date().getMonth(),  new Date().getDate())]);
    }




    // đổ dữ liệu khi edit
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.holiday = this.$commonFuction.convertJsonObject(this.model);
      this.selectedYear = this.holiday.year
      this.maxDate = new Date(this.selectedYear, 11, 31);
      this.minDate = new Date(this.selectedYear, 0, 1);
      this.rangeYear = this.selectedYear+':'+this.selectedYear;
      var list_holiday_items = this.holiday.holiday_items;
      console.log("this.holiday",this.holiday)
      console.log("lamondi",list_holiday_items)
      for (var i = 0 ; i < list_holiday_items.length; i++)
      {
        // this.arr_date[i] = list_holiday_items[i];
        var  item_date = {
          "date":list_holiday_items[i].date,
          "key":(new Date()).getTime()
        }
        item_date.date = moment(item_date.date).format("DD/MM/yyyy")
        this.arr_date.push(item_date)
        this.arr_only_date.push(this.arr_date[i].date)
      }

          const dates = ["20/01/2023", "20/01/2024", "20/01/2022", "20/01/2021"];
    console.log(dates)

// Sắp xếp theo thứ tự giảm dần

      const sortedDates = this.arr_only_date.sort((a, b) => {
        const dateA = new Date(
            parseInt(b.split("/")[2]), // Năm
            parseInt(b.split("/")[1]) - 1, // Tháng (lưu ý: tháng trong JavaScript bắt đầu từ 0)
            parseInt(b.split("/")[0]) // Ngày
        );

        const dateB = new Date(
            parseInt(a.split("/")[2]),
            parseInt(a.split("/")[1]) - 1,
            parseInt(a.split("/")[0])
        );

        return -(dateA - dateB);
      });
      console.log(sortedDates);

      for (var x = 0 ; x < this.arr_only_date.length; x++){
        this.arr_date[x].date = this.arr_only_date[x]
      }

      console.log("congaydi",this.arr_date)

      if (this.mode == 'edit') {

        var selected_company = (this.holiday.company_ids) ? this.holiday.company_ids:null;
        console.log("selected_company:",selected_company)
        var select_item_company = null;
        if (selected_company){
          select_item_company = selected_company[0];
          select_item_company.name = select_item_company.code +" - "+select_item_company.name
        }
        this.selectedCompany = select_item_company;

        console.log("coidchuaw",this.selectedCompany)
      }


    }



    console.log("rrrrrrrr")
      var gqLQueryList = DataServices.getList('company');
      console.log("gqL",gqLQueryList)
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          orderBy: {created_at: 'desc'}
        }
      });
    console.log("kkkkkkkk",resData.data['company'])
    for (var s = 0 ; s < resData.data['company'].length;s++ ){
      resData.data['company'][s].name = resData.data['company'][s].code +" - "+resData.data['company'][s].name
    }

    this.company = resData.data['company'];
      console.log("list data11", this.company);
    // }
  },
  watch: {
    'arr_date'(){
      this.arr_only_date_disable = this.arr_date.map(m => { var arr = m.date.split('/'); return new Date(arr[2], (arr[1]-1), arr[0])})
      console.log(this.arr_only_date_disable);
    }
  },
  methods: {

   async change_year() {
      console.log("ewewewew")
      // const inputYear = parseInt(this.selectedYear);
      // console.log("wqwqwqwq",inputYear)
      // const targetDate = moment({ year: inputYear });
      // this.date = targetDate.format('DD-MM-yyyy');

     this.$set(this, 'date', [new Date(parseInt(this.selectedYear), new Date().getMonth(),  new Date().getDate())]);
     this.maxDate = new Date(this.selectedYear, 11, 31);
     this.minDate = new Date(this.selectedYear, 0, 1);
     this.rangeYear = this.selectedYear+':'+this.selectedYear;
      // return `Ngày tháng năm từ năm ${inputYear} đến hiện tại: ${formattedDate}`;
    },


    // async validateNumericInput() {
    //   this.date = this.date.replace(/[^]/g, "");
    // },



    async SearchCompany(search, loading){

      var list_company =  dataServices.getList("company")
      var resdata_company = await this.$apollo.mutate({
        mutation: list_company,
      });
      this.list_company_all = resdata_company.data['company'];
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          // name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        where_key.graft_code_name_company =  {
          _iregex: this.$commonFuction.search(search),
        };

        console.log("where_key",where_key)
        let {data} = await this.$apollo.query({
          query: DataServices.getList('company',{"fields" : `id name code`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          var text_company = e.code +" - "+e.name
          list_unit.push({id : e.id, name :text_company});
        }
        this.company = list_unit;
      }else{
        // cosnole.log("dasdasd")
        var list_all = [];
        for (let j = 0; j < this.list_company_all.length; j++) {
          const tt = list[j];
          var text_company1 = tt.code +" - "+tt.name
          list_all.push({id : tt.id, name :text_company1});
        }
        this.company =list_all
      }
      return this.company;
    },

   async isDate1BeforeDate2(date1, date2) {
      return moment(date1).isBefore(date2);
    },

    //nút thêm ngày nghỉ
     async add_input() {

       var data = this.date ?  this.date:[]

       console.log("sdasaaa",this.date)

       if (data == '' || data == null || data == undefined || data == 0)
       {
         alert('Vui lòng chọn ngày!')
         return;
       }

       console.log("săqwqwqwq",this.arr_date)
       console.log("data",data)

      for (var s = 0 ; s < data.length ; s++){

        var  item_date = {
          "date":data[s],
          "key":(new Date()).getTime()+this.$commonFuction.makeId(8)
        }
        console.log("qppppppp",this.arr_date[s])
        item_date.date = moment(item_date.date).format("DD/MM/yyyy")
        console.log("lllllllllll",item_date.date)
        var list_date_check = this.arr_date[s] ? this.arr_date[s]:''
        console.log("ttttttttttttt",list_date_check)
        if (list_date_check.date != item_date.date )
        {
          this.arr_date.push(item_date)
          this.arr_only_date.push(item_date.date)
        }
      }

           const dates = ["20/01/2023", "20/01/2024", "20/01/2022", "20/01/2021"];
    console.log(dates)

// Sắp xếp theo thứ tự giảm dần

      const sortedDates = this.arr_only_date.sort((a, b) => {
        const dateA = new Date(
            parseInt(b.split("/")[2]), // Năm
            parseInt(b.split("/")[1]) - 1, // Tháng (lưu ý: tháng trong JavaScript bắt đầu từ 0)
            parseInt(b.split("/")[0]) // Ngày
        );

        const dateB = new Date(
            parseInt(a.split("/")[2]),
            parseInt(a.split("/")[1]) - 1,
            parseInt(a.split("/")[0])
        );

        return -(dateA - dateB);
      });
      console.log("sắqwqwq",sortedDates);

      for (var x = 0 ; x < this.arr_only_date.length; x++){
        this.arr_date[x].date = this.arr_only_date[x]
      }



      this.$set(this, 'date', []);

       console.log("sawqwq",this.arr_date)
       console.log("arr_only_date",this.arr_only_date)
    },

      // nút xóa ngày nghỉ
    async delete_date(key){
       console.log(key,"key")
      var list = []
       list = this.arr_date
      console.log(list,key,"list & key")
      // var list_delete = []
      for ( var i = 0 ; i < list.length ; i++)
      {
        // console.log("log_1")
        // if (list[i].key !=  key)
        // {
        //   console.log("arr_date:",this.arr_date);
        //   list_delete.push(list[i])
        //   console.log("list:",list[i]);
        // }
        if (list[i].key ==  key) {
          this.$delete(this.arr_date, i);
        }
      }
      this.arr_only_date = this.arr_date.map(m => m.date);
      console.log("this.arr_only_datethis.arr_only_date", this.arr_only_date)
      // this.arr_date = list_delete
      // console.log("co xoa dc khong", list_delete)

    },
    async active() {
    },
    backToList() {
      this.$emit('back');
    },
    // kiểm tra dữ liệu đầu vào
    async validateData() {
      this.validationErrors = {};

      let company_add = document.getElementById("vali_company");
      if (this.$commonFuction.isEmptyObject(this.selectedCompany)){
        company_add.setCustomValidity("Tên công ty không được để trống!");
      }else {
        company_add.setCustomValidity("");
      }


      let year_add = document.getElementById("vali_year");
      if (this.$commonFuction.isEmptyObject(this.selectedYear)){
        year_add.setCustomValidity("Năm không được để trống!");
      }else {
        year_add.setCustomValidity("");
      }




      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
 // lấy dữ liệu đưa qua function
    async saveData() {
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }

      if (this.arr_date == '' || this.arr_date == undefined || this.arr_date == null )
      {
        alert("Vui lòng chọn ngày nghỉ")
        return
      }


      var gqLQueryList = DataServices.getList('holiday_item');
      console.log("gqL",gqLQueryList)
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          orderBy: {created_at: 'desc'}
        }
      });
      var listData = resData.data['holiday_item'];
      console.log("sasasas1111",listData)




      console.log("test4")
      console.log("checked",this.checked)

       console.log("timid",this.selectedCompany)
      this.selectedCompany ? this.selectedCompany:0

      console.log("iddau",this.selectedCompany.id)
      var company_id = this.selectedCompany.id
      var year = this.selectedYear

      console.log("danglaig",year)
      var date = []
      var test = this.arr_date
      console.log("asaswqwqwq11",this.arr_date)
       date = document.getElementsByClassName("item-day")
      console.log("wqwqwq",date)
      // console.log("timidwqwq",company_id , year , date[0].textContent)
      var list_date = []
      console.log("item-day",date)
      for (var i = 0 ; i < test.length ; i++)
      {
        console.log("trrwewesa111",test[i].date)

         test[i].date = moment(test[i].date, 'DD/MM/yyyy');

        // Định dạng lại ngày với định dạng MM/DD/yyyy
        test[i].date = test[i].date.format('MM/DD/yyyy');

        // test[i].date = moment(test[i].date).format("MM/DD/yyyy")
        console.log("trrwewesa222",test[i].date)
          list_date.push(test[i])
          // list_date.push(test[i])

      }

      console.log("trrwewesa",list_date)
        var params = {
          // them bang holiday
          company_id:company_id,
          year:year,
          id:this.holiday.id,
          // text_date_all:list_date,
          // them bang holiday item
          date:list_date,
        }
        console.log('saveData', params)
        this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}

//style form add-edit
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;

}
#test{
  font-size: 18px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 450px!important;
  height: 40px!important;
}
.txt-right{
  font-weight: bold;
}
.p-formgrid .fixed-bottom-toolbar{
  border-top: none!important;
}
.text{
  font-weight: normal;
}
.input_width{
  width: 450px!important;
  margin-left: 5px;
}
#data{
  border: none;
}
#tet {
  margin: 0px 0px 12px 21px !important;
}

#test{
  margin: 6px 0px 12px 21px!important;
}

.div-date{
  display: flex;
}
.layout-main{
  background: #00000029;
}

.p-button-warning{
  border: 1px solid #C40380 !important;

}
#back{
  color: #C40380 !important;
}
.vs__clear{
  display: none;
}
.item-day{
  margin-left: 20px;
}
.input_width{
  width: 744px!important;
  height: 44px!important;
}
.txt-right{
  margin-left: 15px!important;
}
.margin_input{
  margin-left: 12px!important;
}
.p-col-fixed{
  margin-left: 21px!important;
}

#button_add_date{
  width: 48px!important;
  margin-left: 12px!important;
  height: 45px!important;
}
.p-fluid .p-button-icon-only{
  width: 48px!important;
  height: 45px!important;
}
.button-delete{
  background-color: red !important;
  margin: 20px 0px 0px 12px !important;
  height: 33px!important ;
  width: 35px !important;

}
.card{
 padding: 0px!important;
}
.manager-title{
  padding: 1rem!important;
}
.fixed-bottom-toolbar{
  padding: 1rem!important;
}





//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }

  .col-12{
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }
  .card{
    padding: 15px 25px 15px 1px;
  }
  .manager-title{
    margin-left: 17px;
  }
  .col-12{
    padding-right: 0px;
  }
  .p-formgrid{
    padding-right: 22px!important;
  }
  .fixed-bottom-toolbar{
    padding-right: 0px!important;
  }
}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
    align-items: center;
    justify-content: center;
  }
  .card{
    padding: 15px 25px 15px 1px;
  }
  .manager-title{
    margin-left: 17px;
  }
  .col-12{
    padding-right: 0px;
  }
  .p-formgrid{
    padding-right: 22px!important;
  }
  .fixed-bottom-toolbar{
    padding-right: 0px!important;
  }
}


</style>
